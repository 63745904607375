<div class="container">
    <mat-dialog-content>
        <h1 style="text-align: center;">
            {{ title }}
        </h1>

        <button class="upload-btn" type="button" mat-raised-button (click)="imagePicker.click()">UPLOAD PICTURE</button>
        <input #imagePicker type="file" (change)="uploadImage($event)">

        <!-- <div *ngIf="this.data.product" class="imageContainer">
            <img [src]="prodImg" width="100%">
        </div> -->

        <div *ngIf="binaryImage || data.product" class="imageContainer">
            <img [src]="binaryImage? binaryImage : prodImg" width="100%">
        </div>

        <form [formGroup]=" newForm" (ngSubmit)="onSubmit()">
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>Vendor</mat-label>
                    <mat-select formControlName="vendor_id">
                        <mat-option *ngFor="let vendor of vendors" [value]="vendor.id">{{ vendor?.username }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" formControlName="name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput id="" cols="30" rows="5" formControlName="description"></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Price</mat-label>
                    <input matInput type="text" formControlName="price">
                </mat-form-field>

                <mat-checkbox style="margin-bottom: 1rem;" color="primary" formControlName="in_stock">
                    Product in stock
                </mat-checkbox>
            </div>

            <div mat-dialog-actions fxLayoutAlign="space-between center">
                <button color="warn" mat-stroked-button mat-dialog-close (click)="dialogRef.close(null)">
                    Cancel
                </button>
                <button class="submit-btn" [disabled]="newForm.invalid" mat-raised-button color="primary" type="submit">
                    Save
                </button>
            </div>
        </form>
    </mat-dialog-content>

</div>
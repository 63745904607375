import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-vendors',
  templateUrl: './manage-vendors.component.html',
  styleUrls: ['./manage-vendors.component.css']
})
export class ManageVendorsComponent implements OnInit {

  newForm: FormGroup;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ManageVendorsComponent>,
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.newForm = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null, Validators.required),
      contact: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      location: new FormControl(null, Validators.required),
    });
    if (this.data.vendor) {
      this.newForm.patchValue(this.data.vendor);
    }
  }

  onSubmit() {
    this.dialogRef.close(this.newForm.value);
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationModel } from 'src/app/services/notifications.service';
import { VendorModel, VendorService } from 'src/app/services/vendor.service';

@Component({
  selector: 'app-manage-notifications',
  templateUrl: './manage-notifications.component.html',
  styleUrls: ['./manage-notifications.component.css']
})
export class ManageNotificationsComponent implements OnInit {

  newForm: FormGroup;
  title: string;
  vendors: VendorModel[];
  notification: NotificationModel;
  allVendorIds: number[] = [];
  viewExisting = false;
  authData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ManageNotificationsComponent>,
    private vendorsService: VendorService,
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.vendors = this.data.vendors;
    this.notification = this.data.notification
    // console.log('Single Notification: ', this.notification);
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    // console.log('Here is your token: ', (this.authData));

    //GET VENDORS INFO
    this.vendorsService._getVendors(this.authData.token).subscribe(vendors => {
      this.vendors = vendors;
      this.vendors.map(async (vendor) => {
        this.allVendorIds.push(vendor.id);
      });
      // console.log('All vendors id: ', this.allVendorIds);
    });

    this.newForm = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
    });
    if (this.data.vendors) {
      this.viewExisting = true;

    }
  }

  onSubmit() {
    let newNotification: NotificationModel;
    newNotification = this.newForm.value;

    let chosenVendor = this.newForm.get('username').value;
    newNotification.vendors = [];

    newNotification.vendors.push(chosenVendor);

    this.dialogRef.close(newNotification);
  }

}

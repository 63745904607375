import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';
import { AppLoaderService } from 'src/app/services/app-loader.service';
import { SmsEmailService, SmsModel } from 'src/app/services/sms-email.service';
import { VendorModel, VendorService } from 'src/app/services/vendor.service';
import { ManageSmsComponent } from './manage-sms/manage-sms.component';


@Component({
  selector: 'app-sms-email',
  templateUrl: './sms-email.component.html',
  styleUrls: ['./sms-email.component.css']
})
export class SmsEmailComponent implements OnInit {

  authData: any;
  vendors: VendorModel[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'username',
    'messageTitle',
    // 'message',
    'messageType',
    'actions'
  ];
  dataSource = new MatTableDataSource<SmsModel>();

  constructor(
    private dialog: MatDialog,
    private smsEmailService: SmsEmailService,
    private snackbar: MatSnackBar,
    private loader: AppLoaderService,
    private vendorsService: VendorService,
  ) { }

  ngOnInit(): void {
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    //GET MESSAGES
    this.getMessages();
    //GET VENDORS INFO
    this.vendorsService._getVendors(this.authData.token).subscribe(vendors => {
      this.vendors = vendors;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getMessages() {
    this.loader.open();
    this.smsEmailService._getMessages(this.authData.token).subscribe(messages => {
      // console.log('Here are your messages: ', messages);
      this.dataSource.data = messages;
      this.loader.close();
    })

  }

  add() {
    const dialogRef = this.dialog.open(ManageSmsComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        title: `CREATE NEW MESSAGE`,
        vendors: this.vendors,
      }
    });

    dialogRef.afterClosed().subscribe((newSmsEmail: SmsModel) => {
      if (newSmsEmail) {
        try {
          delete newSmsEmail.id;
          // console.log('New SMS/EMAIL: ', newSmsEmail);
          //SERVICE & REFRESH DATA
          this.smsEmailService._createSmsEmail(this.authData.token, newSmsEmail).subscribe(newSmsEmailRes => {
            console.log('Sms/Email Res: ', newSmsEmailRes);
            // this.getMessages();

            this.snackbar.open('Success', 'Message successfully sent!', {
              duration: 4000,
              verticalPosition: 'bottom',
            });
          });

        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  edit(SmsEmail: SmsModel) {
    const dialogRef = this.dialog.open(ManageSmsComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        title: `VIEW/EDIT VENDOR`,
        SmsEmail: SmsEmail,
        vendors: this.vendors
      }
    });

  }

  delete(SmsEmail: SmsModel) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '25%',
      data: {
        title: 'Delete Message',
        message: 'Are you sure you want to delete this message?',
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          //SERVICE & REFRESH DATA
          // console.log('Deleted Object: ', SmsEmail);
          this.smsEmailService._deleteSmsEmail(this.authData.token, SmsEmail).subscribe(deleteRes => {
            // console.log('RemedyRES', deleteRes);
            this.getMessages();

            this.snackbar.open('Success', 'Message deleted successfully', {
              verticalPosition: 'bottom',
              duration: 4000,
            });
          });

        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    })
  }

}

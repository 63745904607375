import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VendorsComponent } from './dashboard/vendors/vendors.component';
import { ProductsComponent } from './dashboard/products/products.component';
import { NotificationsComponent } from './dashboard/notifications/notifications.component';
import { SmsEmailComponent } from './dashboard/sms-email/sms-email.component';
import { AuthGaurd } from './services/auth.guard';
import { UsersTableComponent } from './dashboard/users-table/users-table.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent, canActivate: [AuthGaurd],
    children: [
      {
        path: 'vendors',
        component: VendorsComponent
      },
      {
        path: 'products',
        component: ProductsComponent
      },
      {
        path: 'notifications',
        component: NotificationsComponent
      },
      {
        path: 'sms-email',
        component: SmsEmailComponent
      },
      {
        path: 'users',
        component: UsersTableComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGaurd]
})
export class AppRoutingModule { }

<mat-drawer-container class="example-container">

    <mat-drawer #drawer opened disableClose mode="side">
        <div class="img">
            <img src="../../assets/LeCartel-Logo.png" width="150px">
        </div>
        <mat-nav-list>
            <a mat-list-item routerLink="users">USERS</a>
            <a mat-list-item routerLink="vendors">VENDORS</a>
            <a mat-list-item routerLink="products">PRODUCTS</a>
            <a mat-list-item routerLink="notifications">NOTIFICATIONS</a>
            <a mat-list-item routerLink="sms-email">SMS/EMAIL</a>
            <a mat-list-item (click)="logout()">LOGOUT</a>

        </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content>
        <mat-toolbar class="toolbar-1">
            <div class="btn-container">
                <button (click)="drawer.toggle()" mat-icon-button>
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </mat-toolbar>

        <router-outlet></router-outlet>

    </mat-drawer-content>
</mat-drawer-container>
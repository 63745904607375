import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';
import { AppLoaderService } from 'src/app/services/app-loader.service';
import { NotificationModel, NotificationsService } from 'src/app/services/notifications.service';
import { VendorModel, VendorService } from 'src/app/services/vendor.service';
import { ManageNotificationsComponent } from './manage-notifications/manage-notifications.component';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  authData: any;
  vendors: VendorModel[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'username',
    'date',
    'message',
    'email',
    // 'location',
    'actions'
  ];
  dataSource = new MatTableDataSource<NotificationModel>();

  constructor(
    private dialog: MatDialog,
    private vendorsService: VendorService,
    private snackbar: MatSnackBar,
    private loader: AppLoaderService,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    //GET NOTIFICATIONS
    this.getNotifications();
    //GET VENDORS INFO
    this.vendorsService._getVendors(this.authData.token).subscribe(vendors => {
      this.vendors = vendors;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getNotifications() {
    this.loader.open();
    this.notificationService._getNotification(this.authData.token).subscribe(notifications => {
      this.dataSource.data = notifications;
      this.loader.close();
    });
  }

  add() {
    const dialogRef = this.dialog.open(ManageNotificationsComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        title: `CREATE NEW NOTIFICATION`,
        vendors: this.vendors,
      }
    });

    dialogRef.afterClosed().subscribe((newNotification: NotificationModel) => {
      if (newNotification) {
        try {
          //SERVICE & REFRESH DATA
          delete newNotification.username;
          delete newNotification.id;
          // console.log('New Notification: ', newNotification);
          this.notificationService._createNotification(this.authData.token, newNotification).subscribe(res => {
            // console.log('New Notification Res: ', res);
            this.getNotifications();

            this.snackbar.open('Success', 'Message sent!', {
              duration: 4000,
              verticalPosition: 'bottom',
            });
          });
        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }


  edit(notification: NotificationModel) {
    const dialogRef = this.dialog.open(ManageNotificationsComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        title: `VIEW NOTIFICATION`,
        notification: notification
      }
    });
  }

  delete(notification: NotificationModel) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '25%',
      data: {
        title: 'Delete Notification',
        message: 'Are you sure you want to Delete this?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        try {
          // console.log('Delete notification: ', notification);
          //SERVICE & REFRESH DATA
          this.notificationService._deleteNotification(this.authData.token, notification)
            .subscribe(res => {
              // console.log('Delete server res: ', res);
              this.getNotifications();

              this.snackbar.open('Success', 'notification deleted successfully', {
                verticalPosition: 'bottom',
                duration: 4000,
              });
            })
        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    })
  }

}

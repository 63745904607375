import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from './auth/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VendorsComponent } from './dashboard/vendors/vendors.component';
import { ProductsComponent } from './dashboard/products/products.component';
import { NotificationsComponent } from './dashboard/notifications/notifications.component';
import { SmsEmailComponent } from './dashboard/sms-email/sms-email.component';
import { ManageVendorsComponent } from './dashboard/vendors/manage-vendors/manage-vendors.component';
import { ManageNotificationsComponent } from './dashboard/notifications/manage-notifications/manage-notifications.component';
import { ManageProductsComponent } from './dashboard/products/manage-products/manage-products.component';
import { ManageSmsComponent } from './dashboard/sms-email/manage-sms/manage-sms.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { UsersInterceptorService } from './services/interceptor.service';
import { ShortenPipe } from './services/shorten.pipe';
import { UsersTableComponent } from './dashboard/users-table/users-table.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    VendorsComponent,
    ProductsComponent,
    NotificationsComponent,
    SmsEmailComponent,
    ManageVendorsComponent,
    ManageNotificationsComponent,
    ManageProductsComponent,
    ManageSmsComponent,
    AppLoaderComponent,
    DeleteDialogComponent,
    ShortenPipe,
    UsersTableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: UsersInterceptorService,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGaurd implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.checkIfAuthenticated();
    }

    private checkIfAuthenticated() {
        return this.authService.isLoggedIn$
            .pipe(
                map(loggedIn =>
                    loggedIn ? true : this.router.parseUrl('/login'))
            );
    }
}
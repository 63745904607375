import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

const AUTH_DATA = "auth_data";

export class AuthUser {
  email: string;
  digitPin: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private subject = new BehaviorSubject<AuthUser>(null);
  user$: Observable<AuthUser> = this.subject.asObservable();

  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;

  constructor(
    private _http: HttpClient,
    private _router: Router,
  )
  {
    this.isLoggedIn$ = this.user$.pipe(map(user => !!user));
    this.isLoggedOut$ = this.isLoggedIn$.pipe(map(loggedIn => !loggedIn));

    const user = localStorage.getItem(AUTH_DATA);

    if (user) {
      this.subject.next(JSON.parse(user));
    }
  }

  login(user: AuthUser): Observable<AuthUser> {
    return this._http.post<AuthUser>(`https://api.lecartelapp.co.za/public/api/login_pin`, user)
      .pipe(
        tap(user => {
          // console.log('User: ', user);
          this.subject.next(user);
          localStorage.setItem(AUTH_DATA, JSON.stringify(user));

          setTimeout(() => {
            this.logout();
          }, 3600000)
        }),
      );
  }

  logout() {
    localStorage.removeItem(AUTH_DATA);
    this.subject.next(null);
    this._router.navigateByUrl('');
  }

  forgotPassword() {

  }
}

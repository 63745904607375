import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductModel } from './product.service';

export class UserModel {
  cellphone: string;
  created_at?: string;
  email: string;
  id?: number;
  location: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  constructor(
    private http: HttpClient,
  ) { }

  _getUsers(token: string): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`https://api.lecartelapp.co.za/public/api/mobile_users?token=${token}`);
  }
}

<div class="container">
    <div fxLayoutAlign="space-between center">
        <button (click)="add()" mat-raised-button>ADD VENDOR</button>
        <mat-form-field class="example-full-width">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Username Column -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> Vendor Name </th>
            <td mat-cell *matCellDef="let vendor"> {{vendor.username}} </td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="create_at">
            <th mat-header-cell *matHeaderCellDef> Date Created </th>
            <td mat-cell *matCellDef="let vendor"> {{vendor.created_at | date}} </td>
        </ng-container>

        <!-- Contact Column -->
        <ng-container matColumnDef="contact">
            <th mat-header-cell *matHeaderCellDef> Contact </th>
            <td mat-cell *matCellDef="let vendor"> {{vendor.contact}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let vendor"> {{vendor.email}} </td>
        </ng-container>

        <!-- Location Column -->
        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef> Location </th>
            <td mat-cell *matCellDef="let vendor"> {{vendor.location}} </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="header-align-right">
                Actions
            </th>
            <td mat-cell *matCellDef="let vendor" class="icons-align-right">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="edit(vendor)">
                        <mat-icon>visibility</mat-icon>
                        <span>View</span>
                    </button>
                    <button mat-menu-item (click)="delete(vendor)">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>
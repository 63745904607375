import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class NotificationModel {
  created_at?: Date
  id?: number;
  message: string;
  title: string;
  vendor_email: string;
  username: string;
  vendors: number[];
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
  ) { }

  _getNotification(token: string): Observable<NotificationModel[]> {
    return this.http.get<NotificationModel[]>(`https://api.lecartelapp.co.za/public/api/notifications?token=${token}`);
  }

  _createNotification(token: string, newNotification: NotificationModel): Observable<NotificationModel> {
    return this.http.post<NotificationModel>(`https://api.lecartelapp.co.za/public/api/notify_vendors?token=${token}`, newNotification);
  }

  //CANT UPDATE EMAIL AFTER ITS SENT
  // _updateNotification(token: string, notification: VendorModel): Observable<NotificationModel> {
  //   return this.http.put<NotificationModel>(`https://api.lecartelapp.co.za/public/api/update_vendor/${vendor.id}?token=${token}`, notification);
  // }

  _deleteNotification(token: string, notification: NotificationModel) {
    return this.http.delete(`https://api.lecartelapp.co.za/public/api/delete_notification/${notification.id}?token=${token}`);
  }
}

<div *ngIf="!viewExisting" class="view-container">
    <h1 style="text-align: center;">View Message</h1>

    <h3>Date sent: {{ notification.created_at | date }}</h3>
    <h3>Sent to: {{ notification.vendor_email }}</h3>
    <h3>Message Title: {{ notification.title }}</h3>
    <h3>Message: {{ notification.message }}</h3>

    <div mat-dialog-actions fxLayoutAlign="center center">
        <button color="warn" mat-stroked-button mat-dialog-close (click)="dialogRef.close(null)">
            Close
        </button>
    </div>
</div>


<div *ngIf="viewExisting" class="container">

    <h1 style="text-align: center;">
        {{ title }}
    </h1>

    <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Vendor</mat-label>
                <mat-select formControlName="username">
                    <mat-option *ngFor="let vendor of vendors" [value]="vendor.id">{{ vendor?.username }}
                    </mat-option>
                    <mat-option [value]="allVendorIds">All Vendors</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    Title
                </mat-label>
                <input type="text" formControlName="title" matInput>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    Message
                </mat-label>
                <textarea type="text" formControlName="message" matInput cols="30" rows="10"></textarea>
            </mat-form-field>
        </div>

        <div mat-dialog-actions fxLayoutAlign="space-between center">
            <button color="warn" mat-stroked-button mat-dialog-close (click)="dialogRef.close(null)">
                Cancel
            </button>
            <button [disabled]="newForm.invalid" class="submit-btn" mat-raised-button color="primary" type="submit">
                Save
            </button>
        </div>
    </form>
</div>
<div class="container">

  <div fxLayoutAlign="space-between center">

    <!-- <button mat-raised-button>ADD USER</button> -->

    <mat-form-field class="example-full-width">
      <mat-label>Search </mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>

    <div>

      <button mat-raised-button color="danger" (click)="downloadMyFile()"> Export <mat-icon inline>get_app</mat-icon>
      </button>

    </div>

    <div>

      <div class="col-lg-12 text-center">
        <button mat-raised-button color="primary" (click)="file.click()"> Import </button>
        <input type="file" (change)="handleCSV($event.target.files)" class="custom-file-input" style="display: none;"
          #file id="category_banner" accept=".jpeg,.png,.jpg,.csv">

        <input type="hidden" name="category_banner" />
      </div>

    </div>

  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Username </th>
      <td mat-cell *matCellDef="let user"> {{ user.username | titlecase}} </td>
    </ng-container>

    <ng-container matColumnDef="cellphone">
      <th mat-header-cell *matHeaderCellDef> Cellphone </th>
      <td mat-cell *matCellDef="let user"> {{ user.cellphone }} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let user"> {{ user.email }} </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="header-align-right">
        Actions
      </th>
      <td mat-cell *matCellDef="let user" class="icons-align-right">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item>
            <mat-icon>visibility</mat-icon>
            <span>View</span>
          </button>
          <button mat-menu-item>
            <mat-icon>delete_forever</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SmsModel } from 'src/app/services/sms-email.service';
import { VendorModel, VendorService } from 'src/app/services/vendor.service';

@Component({
  selector: 'app-manage-sms',
  templateUrl: './manage-sms.component.html',
  styleUrls: ['./manage-sms.component.css']
})
export class ManageSmsComponent implements OnInit {

  newForm: FormGroup;
  title: string;
  vendors: VendorModel[];
  message: SmsModel
  allVendorIds: number[] = [];
  viewExisting = false;
  authData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ManageSmsComponent>,
    private vendorsService: VendorService,
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.vendors = this.data.vendors;
    this.message = this.data.SmsEmail
    // console.log('Single Message: ', this.message);
    this.authData = JSON.parse(localStorage.getItem('auth_data'));

    //GET VENDORS INFO
    this.vendorsService._getVendors(this.authData.token).subscribe(vendors => {
      this.vendors = vendors;
      this.vendors.map(async (vendor) => {
        this.allVendorIds.push(vendor.id);
      });
      // console.log('All vendors id: ', this.allVendorIds);
    });

    this.newForm = new FormGroup({
      id: new FormControl(null),
      vendors: new FormControl(null, Validators.required),
      message_type: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
    });
    if (this.data.SmsEmail) {
      this.viewExisting = true;
    }
  }

  onSubmit() {
    let newSmsEmail: SmsModel
    newSmsEmail = this.newForm.value;

    let chosenVendor = this.newForm.get('vendors').value;
    newSmsEmail.vendors = [];
    newSmsEmail.vendors.push(chosenVendor);

    this.dialogRef.close(this.newForm.value);
  }

}

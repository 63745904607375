import { Component, OnInit, ViewChild } from '@angular/core';
import { UserModel, UserServiceService } from 'src/app/services/user-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { AppLoaderService } from 'src/app/services/app-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.css']
})

export class UsersTableComponent implements OnInit {

  authData: any;
  fileName = '';
  requestOptions: any

  selectedFile: any;
  binaryImage: any;

  displayedColumns: string[] = [
    'username',
    'cellphone',
    'email',
    // 'location'
  ];

  dataSource = new MatTableDataSource<UserModel>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private userService: UserServiceService,
    private loader: AppLoaderService,
    private snackbar: MatSnackBar,
    private _http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    //GET USERS
    this.getUsers();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getUsers() {
    try {
      this.loader.open();
      this.userService._getUsers(this.authData.token).subscribe(users => {
        console.log('Here are your users: ', users);
        this.dataSource.data = users['users'];
        this.loader.close();
      });
    }
    catch (err) {
      console.log(err);
      this.snackbar.open('Error', 'Something went wrong!', {
        duration: 4000,
        verticalPosition: 'bottom',
      });
      this.loader.close();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handleCSV(files: FileList) {
    this.loader.open();
    const formData = new FormData();

    formData.append('file', files[0], files[0].name);

    this._http.post('http://lecartelapi.phpapplord.co.za/leCartel_api_test/public/api/import', formData).subscribe((res) => {
      console.log(res);

      this.snackbar.open('Success', 'CSV successfully uploaded', {
        duration: 4000,
        verticalPosition: 'bottom',
      });
      this.loader.close();

    }, error => {
      console.log("error", error)
      this.loader.close();
      this.snackbar.open('Error', 'Something went wrong!', {
        duration: 4000,
        verticalPosition: 'bottom',
      });

    });

  }

  downloadMyFile() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'http://lecartelapi.phpapplord.co.za/leCartel_api_test/public/api/export_csv');
    link.setAttribute('download', `Users.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }




}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class ProductModel {
  id?: number | string;
  vendor_id: number | string;
  name: string;
  description: string;
  price: string | number;
  image: any | string;
  created_at?: Date;
  in_stock: boolean;
  username: string;
}

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  constructor(
    private http: HttpClient,
  ) { }

  _getProducts(token: string): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(`https://api.lecartelapp.co.za/public/api/products?token=${token}`);
  }

  _createProduct(newProduct: ProductModel, token: string): Observable<ProductModel> {
    return this.http.post<ProductModel>(`https://api.lecartelapp.co.za/public/api/create_product?token=${token}`, newProduct);
  }

  _updateProduct(token: string, product: ProductModel): Observable<ProductModel> {
    delete product.username;
    return this.http.put<ProductModel>(`https://api.lecartelapp.co.za/public/api/update_product/${product.id}?token=${token}`, product);
  }

  _deleteProduct(token: string, product: ProductModel) {
    return this.http.delete(`https://api.lecartelapp.co.za/public/api/delete_product/${product.id}?token=${token}`);
  }
}

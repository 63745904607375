import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppLoaderService } from 'src/app/services/app-loader.service';
import { AuthService, AuthUser } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(
    private router: Router,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private loader: AppLoaderService,
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    })
  }

  onSubmit() {
    let user: AuthUser = {
      email: this.loginForm.value.username,
      digitPin: this.loginForm.value.password
    }
    this.loader.open();
    this.authService.login(user).subscribe((userRes) => {
      if (userRes) {
        this.router.navigateByUrl('/dashboard/vendors');

        this.snackbar.open('Success', 'Successfully logged in!', {
          duration: 4000,
          verticalPosition: 'bottom',
        });
        this.loader.close();
      }
      return
    }, err => {
      if (err) {
        console.log(err);
        this.loader.close();
        this.snackbar.open('Error', 'Something went wrong!', {
          duration: 4000,
          verticalPosition: 'bottom',
        });
      }
    });

  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class SmsModel {
  id?: number;
  vendor_id: number | number[];
  title: string;
  message: string;
  vendors: number[];
  message_type: string[];
}

@Injectable({
  providedIn: 'root'
})
export class SmsEmailService {

  constructor(
    private http: HttpClient,
  ) { }

  _getMessages(token: string): Observable<SmsModel[]> {
    return this.http.get<SmsModel[]>(`https://api.lecartelapp.co.za/public/api/mobile_notifications?token=${token}`);
  }

  _createSmsEmail(token: string, newSmsEmail: SmsModel): Observable<SmsModel> {
    return this.http.post<SmsModel>(`https://api.lecartelapp.co.za/public/api/notify_mobile_users?token=${token}`, newSmsEmail);
  }

  _deleteSmsEmail(token: string, SmsEmail: SmsModel) {
    return this.http.delete(`https://api.lecartelapp.co.za/public/api/delete_mobile_notification/${SmsEmail.id}?token=${token}`);
  }
}

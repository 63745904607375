<div class="container">
    <h1 style="text-align: center;">
        {{ title }}
    </h1>

    <form [formGroup]="newForm" (ngSubmit)="onSubmit()">
        <div>
            <mat-form-field appearance="outline">
                <mat-label>
                    Vendor Name
                </mat-label>
                <input type="text" formControlName="username" matInput>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    Location
                </mat-label>
                <textarea cols="30" rows="5" formControlName="location" matInput></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    Contact
                </mat-label>
                <input type="text" formControlName="contact" matInput>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    Email
                </mat-label>
                <input type="text" formControlName="email" matInput>
            </mat-form-field>
        </div>

        <div mat-dialog-actions fxLayoutAlign="space-between center">
            <button color="warn" mat-stroked-button mat-dialog-close (click)="dialogRef.close(null)">
                Cancel
            </button>
            <button class="submit-btn" [disabled]="newForm.invalid" mat-raised-button color="primary" type="submit">
                Save
            </button>
        </div>
    </form>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { catchError } from 'rxjs/operators';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';
import { AppLoaderService } from 'src/app/services/app-loader.service';
import { VendorService, VendorModel } from 'src/app/services/vendor.service';
import { ManageVendorsComponent } from './manage-vendors/manage-vendors.component';


@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements OnInit {

  authData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'username',
    'create_at',
    'contact',
    'email',
    'location',
    'actions'
  ];
  dataSource = new MatTableDataSource<VendorModel>();

  constructor(
    private vendorService: VendorService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private loader: AppLoaderService,
  ) { }

  ngOnInit(): void {
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    this.getVendors();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getVendors() {
    this.loader.open();
    this.vendorService._getVendors(this.authData.token).subscribe(vendors => {
      console.log('Here are your vendors', vendors);
      this.dataSource.data = vendors;
      this.loader.close();
    }, err => {
      console.log('Token Error: ', err)
    })
  }

  add() {
    const dialogRef = this.dialog.open(ManageVendorsComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        title: `CREATE NEW VENDOR`
      }
    });

    dialogRef.afterClosed().subscribe(async (newVendor: VendorModel) => {
      if (newVendor) {
        try {
          console.log('New vendor: ', newVendor);
          //SERVICE & REFRESH DATA
          this.vendorService._createVendor(this.authData.token, newVendor).subscribe(newVendorRes => {
            // console.log('Vendor Res: ', newVendorRes);
            this.getVendors();

            this.snackbar.open('Success', 'Vendor successfully created!', {
              duration: 4000,
              verticalPosition: 'bottom',
            });
          });

        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  edit(vendor: VendorModel) {
    const dialogRef = this.dialog.open(ManageVendorsComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        title: `VIEW/EDIT VENDOR`,
        vendor: vendor
      }
    });

    dialogRef.afterClosed().subscribe(async (editVendor: VendorModel) => {
      if (editVendor === null) {
        return;
      }
      else if (editVendor) {
        try {
          //SERVICE & REFRESH DATA
          this.vendorService._updateVendor(this.authData.token, editVendor).subscribe(editVendorRes => {
            // console.log('Vendor Res: ', editVendorRes);
            this.getVendors();

            this.snackbar.open('Success', 'Vendor successfully updated!', {
              duration: 4000,
              verticalPosition: 'bottom',
            });
          });

        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  delete(vendor: VendorModel) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '25%',
      data: {
        title: 'Delete Vendor',
        message: 'Are you sure you want to delete this Vendor?',
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          //SERVICE & REFRESH DATA
          this.vendorService._deleteVendor(this.authData.token, vendor).subscribe(deleteRes => {
            console.log('RemedyRES', deleteRes);
            this.getVendors();

            this.snackbar.open('Success', 'Vendor deleted successfully', {
              verticalPosition: 'bottom',
              duration: 4000,
            });
          });

        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    })
  }

}

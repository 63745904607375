<div class="container">
    <div fxLayoutAlign="space-between center">
        <button (click)="add()" mat-raised-button>ADD PRODUCT</button>
        <mat-form-field class="example-full-width">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Username Column -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> Vendor Name </th>
            <td mat-cell *matCellDef="let product"> {{product.username}} </td>
        </ng-container>

        <!-- DateCreated Column -->
        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef> Date Created </th>
            <td mat-cell *matCellDef="let product"> {{product.created_at | date}} </td>
        </ng-container>

        <!-- Contact Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let product"> {{product.name}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let product"> {{product.description}} </td>
        </ng-container>

        <!-- In Stock Column -->
        <ng-container matColumnDef="in_stock">
            <th mat-header-cell *matHeaderCellDef> In Stock </th>
            <td mat-cell *matCellDef="let product"> {{product.in_stock == 1 ? 'True' : 'False'}} </td>
        </ng-container>

        <!-- Location Column -->
        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let product"> {{product.price}} </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="header-align-right">
                Actions
            </th>
            <td mat-cell *matCellDef="let product" class="icons-align-right">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="edit(product)">
                        <mat-icon>visibility</mat-icon>
                        <span>View</span>
                    </button>
                    <button mat-menu-item (click)="delete(product)">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>
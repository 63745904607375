import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class VendorModel {
  id: number; //Vendor Id 
  user_id?: number;
  created_at?: Date;
  username: string;
  location: string;
  contact: string | number;
  email: string;
  updated_at?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(
    private http: HttpClient,
  ) { }

  _getVendors(token: string): Observable<VendorModel[]> {
    return this.http.get<VendorModel[]>(`https://api.lecartelapp.co.za/public/api/vendors?token=${token}`);
  }

  _createVendor(token: string, newVendor: VendorModel): Observable<VendorModel> {
    return this.http.post<VendorModel>(`https://api.lecartelapp.co.za/public/api/create_vendor?token=${token}`, newVendor);
  }

  _updateVendor(token: string, vendor: VendorModel): Observable<VendorModel> {
    return this.http.put<VendorModel>(`https://api.lecartelapp.co.za/public/api/update_vendor/${vendor.id}?token=${token}`, vendor);
  }

  _deleteVendor(token: string, vendor: VendorModel) {
    return this.http.delete(`https://api.lecartelapp.co.za/public/api/delete_vendor/${vendor.id}?token=${token}`);
  }
}

<div class="container" fxLayout="column" fxLayoutAlign="center center">
    <img src="../../../assets/LeCartel-Logo.png" width="190px">
    <mat-card class="login-card">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <mat-card-header fxLayout="column" fxLayoutAlign=" center">
                <h1>Le Cartel</h1>
                <h3>Please login to your account</h3>
            </mat-card-header>
            <mat-card-content>

                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" formControlName="username">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                </mat-form-field>

            </mat-card-content>
            <mat-card-actions align="center">
                <button [disabled]="loginForm.invalid" type="submit" mat-raised-button>LOGIN</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
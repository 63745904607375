import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ManageProductsComponent } from './manage-products/manage-products.component';
import { MatDialog } from '@angular/material/dialog';
import { VendorModel, VendorService } from 'src/app/services/vendor.service';
import { map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { AppLoaderService } from 'src/app/services/app-loader.service';
import { ProductService, ProductModel } from 'src/app/services/product.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {

  authData: any;
  vendors: VendorModel[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'username',
    'created_at',
    'name',
    'description',
    'in_stock',
    'price',
    'actions'
  ];
  dataSource = new MatTableDataSource<ProductModel>();

  constructor(
    private dialog: MatDialog,
    private vendorsService: VendorService,
    private productsService: ProductService,
    private loader: AppLoaderService,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    
    //GET PRODUCTS
    this.getProducts();
    //GET VENDORS INFO
    this.vendorsService._getVendors(this.authData.token).subscribe(vendors => {
      this.vendors = vendors;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getProducts() {
    try {
      this.loader.open();
      this.productsService._getProducts(this.authData.token).subscribe(products => {
        console.log(products);
        this.dataSource.data = products;
        this.loader.close();
      });
    }
    catch (err) {
      console.log(err);
      this.snackbar.open('Error', 'Something went wrong!', {
        duration: 4000,
        verticalPosition: 'bottom',
      });
      this.loader.close();
    }

  }

  add() {
    const dialogRef = this.dialog.open(ManageProductsComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        title: `CREATE NEW PRODUCT`,
        vendors: this.vendors,
      }
    });

    dialogRef.afterClosed().subscribe((newProduct: ProductModel) => {
      if (newProduct) {
        try {
          //SERVICE & REFRESH DATA
          this.productsService._createProduct(newProduct, this.authData.token).subscribe(res => {
            console.log('Product res: ', res);
            this.getProducts();

            this.snackbar.open('Success', 'Product successfully created!', {
              duration: 4000,
              verticalPosition: 'bottom',
            });
          });

        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }

      }
    })
  }

  edit(product: ProductModel) {
    const dialogRef = this.dialog.open(ManageProductsComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        title: `VIEW/EDIT PRODUCT`,
        product: product,
        vendors: this.vendors
      }
    });

    dialogRef.afterClosed().subscribe((editProduct: ProductModel) => {
      if (editProduct === null) {
        return;
      }
      else if (editProduct) {
        try {
          //SERVICE & REFRESH DATA
          this.productsService._updateProduct(this.authData.token, editProduct).subscribe(editProductRes => {
            // console.log('Product Res: ', editProductRes);
            this.getProducts();

            this.snackbar.open('Success', 'Product successfully updated!', {
              duration: 4000,
              verticalPosition: 'bottom',
            });
          });

        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    })
  }

  delete(product: ProductModel) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '25%',
      data: {
        title: 'Delete Vendor',
        message: 'Are you sure you want to delete this Product?',
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        try {
          //SERVICE & REFRESH DATA
          this.productsService._deleteProduct(this.authData.token, product).subscribe(deleteRes => {
            console.log('RemedyRES', deleteRes);
            this.getProducts();

            this.snackbar.open('Success', 'Product deleted successfully', {
              verticalPosition: 'bottom',
              duration: 4000,
            });
          });

        }
        catch (err) {
          console.log(err);
          this.snackbar.open('Error', 'Something went wrong!', {
            duration: 4000,
            verticalPosition: 'bottom',
          });
        }
      }
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { callbackify } from 'node:util';
import { AppLoaderService } from 'src/app/services/app-loader.service';
import { ProductModel, ProductService } from 'src/app/services/product.service';
import { VendorModel } from 'src/app/services/vendor.service';

@Component({
  selector: 'app-manage-products',
  templateUrl: './manage-products.component.html',
  styleUrls: ['./manage-products.component.css']
})
export class ManageProductsComponent implements OnInit {

  newForm: FormGroup;
  title: string;
  vendors: VendorModel[];
  product: ProductModel;
  selectedFile: any;
  binaryImage: any;
  prodImg: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ManageProductsComponent>,
    private loader: AppLoaderService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    
    this.title = this.data.title;
    this.vendors = this.data.vendors;
    this.product = this.data.product;
    // console.log('Passed back Vendors: ', this.vendors);
    // console.log('Passed back Product: ', this.product);

    this.prodImg = `https://api.lecartelapp.co.za/public/${this.product?.image}`

    this.newForm = new FormGroup({
      id: new FormControl(null),
      vendor_id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      price: new FormControl(null, Validators.required),
      image: new FormControl(null),
      in_stock: new FormControl(false),
    });
    if (this.data.product) {
      this.newForm.patchValue(this.data.product);
    }
  }

  async uploadImage(event: Event) {

    this.selectedFile = (event.target as HTMLInputElement).files[0];
    // console.log('selected file: ', this.selectedFile);

    const reader = new FileReader();
    reader.onload = () => {
      this.binaryImage = reader.result;
      // console.log('HERE IS YOUR BINARY: ', this.binaryImage);
    }
    reader.readAsDataURL(this.selectedFile);
  }

  onSubmit() {

    if (this.binaryImage) {
      this.newForm.patchValue({ image: this.binaryImage });
    }

    console.log(this.newForm.value);
    this.dialogRef.close(this.newForm.value);
  }

}

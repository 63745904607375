<div class="container">
    <div fxLayoutAlign="space-between center">
        <button (click)="add()" mat-raised-button>NEW MESSAGE</button>
        <mat-form-field class="example-full-width">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Username Column -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> Vendor Name </th>
            <td mat-cell *matCellDef="let message"> {{message.username}} </td>
        </ng-container>

        <!-- MessageType Column -->
        <ng-container matColumnDef="messageType">
            <th mat-header-cell *matHeaderCellDef> Message Type </th>
            <td mat-cell *matCellDef="let message"> {{message.message_type | titlecase}} </td>
        </ng-container>

        <!-- MessageTitle Column -->
        <ng-container matColumnDef="messageTitle">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let message"> {{message.title}} </td>
        </ng-container>

        <!-- Message Column -->
        <!-- <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef> Message </th>
            <td mat-cell *matCellDef="let message"> {{message.message}} </td>
        </ng-container> -->

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="header-align-right">
                Actions
            </th>
            <td mat-cell *matCellDef="let message" class="icons-align-right">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="edit(message)">
                        <mat-icon>visibility</mat-icon>
                        <span>View</span>
                    </button>
                    <button mat-menu-item (click)="delete(message)">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>